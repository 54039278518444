<template>
  <div>
    <div class="btn" @click="drawer = true">党员<br/>一览表</div>
    <div class="w">
      <el-row :gutter="20">
        <img src="https://read.culturalcloud.net/wyy-meeting/202304/150851205kmc.jpg" width="100%"
          style="object-fit: cover;" alt="">
        <img src="https://read.culturalcloud.net/wyy-meeting/202304/150845542gev.png" width="100%" alt="">
        <el-form @submit.native.prevent :model="queryParams" ref="queryForm" size="small" inline label-width="auto">
          <el-form-item label="" prop="title">
            <el-input v-model="queryParams.title" placeholder="请输入搜索内容" clearable @keyup.enter.native="handleQuery"
              @clear="resetQuery" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
            <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button> -->
          </el-form-item>
        </el-form>

        <el-col :title="item.title" @click.native="$router.push({ path: '/video/' + item.id })" :span="6" :offset="0"
          v-for="(item, index) in list" :key="index">
          <div class="list">
            <div class="img">
              <img :src="item.titleImg" alt="">
            </div>
            <div class="info">
              <p class="es-1 title"> {{ item.title }}</p>
              <!-- <p class="es-2 sub"> {{ item.description? item.description:'暂无简介'}}</p> -->
              <!-- <p class="es-1 view"> 观看次数:{{ item.view}}</p> -->
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- <pagination
    style="display: flex;justify-content: center;"
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->
    </div>
    <el-drawer title="党员一览表" :visible.sync="drawer">
      <div class="person-num">
        <div  style="margin-bottom: 30px;" v-for="(item,key,index) in person" :key="index"> 
        <div style="font-size:22px;color: #ccc;font-weight: bold;">{{ key }}</div>
        <span v-for="(j,i) in item" :key="i" @click="searchName(j)"> <el-link type="primary" >{{j}}</el-link></span>
      </div>
      </div>
     
    </el-drawer>

  </div>
</template> 

<script>
import { getVideoList } from "@/api";
import Pagination from '@/components/Pagination.vue'
export default {
  name: 'video-list',
  components: {
    Pagination
  },
  data() {
    return {
      drawer: false,
      total: 0,
      list: [],
      person:{
    "C": [
        "蔡畅",
        "常香玉",
        "陈潭秋",
        "陈延年",
        "程开甲",
        "丛飞"
    ],
    "D": [
        "邓恩铭",
        "邓稼先",
        "邓中夏"
    ],
    "F": [
        "樊锦诗",
        "方志敏"
    ],
    "G": [
        "谷文昌",
        "关向应"
    ],
    "H": [
        "何叔衡",
        "胡福明",
        "华罗庚",
        "黄大年",
        "黄继光",
        "黄文秀"
    ],
    "J": [
        "吉鸿昌",
        "江竹筠",
        "焦裕禄"
    ],
    "K": [
        "孔繁森"
    ],
    "L": [
        "雷锋",
        "李保国",
        "李达",
        "李大钊",
        "李芳",
        "李连成",
        "李硕勋",
        "李四光",
        "林伯渠",
        "林祥谦",
        "刘伯坚",
        "刘胡兰",
        "刘志丹",
        "罗炳辉",
        "罗盛教"
    ],
    "M": [
        "马本斋",
        "麦贤得",
        "毛岸英",
        "茅以升"
    ],
    "N": [
        "聂耳"
    ],
    "O": [
        "欧阳海"
    ],
    "P": [
        "彭加木",
        "彭湃",
        "彭雪枫"
    ],
    "Q": [
        "钱学森",
        "邱少云"
    ],
    "R": [
        "热地",
        "任羊成",
        "任长霞"
    ],
    "S": [
        "时传祥",
        "史来贺",
        "孙家栋"
    ],
    "T": [
        "童第周",
        "屠呦呦"
    ],
    "W": [
        "王淦昌",
        "王继才",
        "王尽美",
        "王进喜",
        "王蒙",
        "王启民",
        "王若飞",
        "王文教",
        "王有德",
        "韦拔群",
        "吴焕先",
        "吴金印",
        "吴玉章",
        "吴运铎"
    ],
    "X": [
        "夏明翰",
        "冼星海",
        "向警予",
        "萧楚女",
        "谢觉哉",
        "谢子长",
        "徐特立",
        "许世友"
    ],
    "Y": [
        "杨闇公",
        "杨根思",
        "杨靖宇",
        "杨开慧",
        "杨匏安",
        "杨善洲",
        "叶挺",
        "于敏",
        "恽代英"
    ],
    "Z": [
        "张富清",
        "张海迪",
        "张思德",
        "张玉滚",
        "赵尚志",
        "赵世炎",
        "赵一曼",
        "钟南山",
        "周维炯",
        "竺可桢",
        "邹韬奋",
        "左权"
    ]
},
      queryParams: {
        pageNum: 1,
        pageSize: 100,
        type:'党建视频'
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    searchName(name){
      this.queryParams.title=name
      this.drawer=false;
      this.getList();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs['queryForm'].resetFields()
      // this.resetForm("queryForm");
      this.handleQuery();
    },
    getList() {
      getVideoList(this.queryParams).then(res => {
        this.total = res.total;
        this.list = res.rows
      });
    }
  },

}
</script>

<style lang="scss" scoped>
::v-deep .el-link.el-link--primary {
  color: #6f95bc;
}
.btn {
  z-index: 999;
  width: 100px;
  text-align: center;
  // transform: translateY(-50%);
  font-weight: bold;
  font-family: "宋体";
  letter-spacing: 2px;
  font-size: 20px;
  cursor: pointer;
  background-color: #C51112;
  color: #FCE401;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  position: fixed;
  right: 5%;
  top: 15%;
  // writing-mode: vertical-lr
}
.person-num {
  padding: 30px;
  
  span {
    word-break:keep-all;
    // white-space: pre;
    margin-right: 20px;
    line-height: 30px;
  }
}

.list {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: .2);
    transition: all ease-in-out .3s;
  }

  .img {

    width: 100%;
    height: 150px;
    // margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .info {
    // width: 68%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
    }

    .sub {
      margin-bottom: auto;
      color: #666;
      word-break: break-all;
      font-size: 12px;
    }

    .view {
      color: #ccc;
      font-size: 12px;
    }
  }
}
</style>